.blink_me {
    box-sizing: border-box;
    animation: blinker 0.71s linear infinite;
}

.blink_me_slow {
    box-sizing: border-box;
    animation: blinker 1.25s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}