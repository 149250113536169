.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-1{
    fill: #C90303;
}

.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-2{
    fill: #C90303;
}

.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-3{
    fill: #C90303;
}

.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-4{
    fill: #1A88E3;
}

.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-5{
    fill: #1A88E3;
}


.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-6{
    fill: #0C6002;
}

.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-7{
    fill: #0C6002;
}

.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-8{
    fill: #044000;
}

.sleep-sessions-gh-chart .react-calendar-heatmap .sleep-9{
    fill: #044000;
}

.sleep-sessions-gh-chart .react-calendar-heatmap .empty{
    fill: whitesmoke;
}
