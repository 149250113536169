.ranges_tool .multi-range-slider {
    box-shadow: none !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid whitesmoke !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.multi-range-slider .ruler{
    display: none !important;
}
.multi-range-slider .label-min{
    font-size: 16px !important;
}

.multi-range-slider .label-max{
    font-size: 16px !important;
}